.theme-list {
  position: absolute;
  z-index: 10;
}

.themes-enter {
  opacity: 0.01;
  transition: opacity 0.25s ease-in;
}

.themes-enter.themes-enter-active {
  opacity: 1;
}

.themes-leave {
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

.themes-leave.themes-leave-active {
  opacity: 0.01;
}
