.main-content {
  display: flex;
  justify-content: center;
}

.contact {
  font-family: 'Inconsolata', monospace;
  color: #64b6e7;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    font-size: 70%;
  }

  @media screen and (max-width: 500px) {
    font-size: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 40%;
  }

  .y {
    color: #d7dba9;
  }

  .w {
    color: white;
  }

  .b {
    color: #337dd2;
  }

  .o {
    color: #c1916f;
  }

  .c {
    color: grey;
  }

  .g {
    color: #4ec9b0;
  }
}