body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: unset;
  border: unset;
  border-radius: unset;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.colorBubble {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer; }

.theme-list {
  position: absolute;
  z-index: 10; }

.themes-enter {
  opacity: 0.01;
  transition: opacity 0.25s ease-in; }

.themes-enter.themes-enter-active {
  opacity: 1; }

.themes-leave {
  opacity: 1;
  transition: opacity 0.25s ease-in; }

.themes-leave.themes-leave-active {
  opacity: 0.01; }

#profile-pic {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-right: 1rem; }

.link-container {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem; }


.main-content {
  display: flex;
  justify-content: center; }

.contact {
  font-family: 'Inconsolata', monospace;
  color: #64b6e7;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 900px) {
    .contact {
      font-size: 70%; } }
  @media screen and (max-width: 500px) {
    .contact {
      font-size: 50%; } }
  @media screen and (max-width: 400px) {
    .contact {
      font-size: 40%; } }
  .contact .y {
    color: #d7dba9; }
  .contact .w {
    color: white; }
  .contact .b {
    color: #337dd2; }
  .contact .o {
    color: #c1916f; }
  .contact .c {
    color: grey; }
  .contact .g {
    color: #4ec9b0; }

body {
  margin: 0; }

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: color 0.25s ease-in-out, border 0.25s ease-in-out; }

.content {
  max-width: 740px;
  margin: 1rem auto 1rem auto; }

.particles-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  z-index: -1; }
  .particles-container > .wrapper {
    width: 100%;
    height: 100vh; }
    .particles-container > .wrapper > canvas {
      position: absolute; }

.theme-changer {
  margin: 1rem 0 0 1rem; }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.link-container {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem; }

.fade-enter {
  opacity: 0;
  z-index: 1; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in; }

a {
  color: unset;
  text-decoration: none; }

.main {
  position: relative;
  padding: 0 1rem; }

.theme-picker-container {
  position: absolute;
  top: 1rem;
  right: 1rem; }
  @media screen and (max-width: 319px) {
    .theme-picker-container {
      display: none; } }

.slow-fade-enter {
  opacity: 0.01; }

.slow-fade-enter.slow-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in; }

.slow-fade-leave {
  opacity: 1; }

.slow-fade-leave.slow-fade-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in; }

