body {
  margin: 0;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: color 0.25s ease-in-out, border 0.25s ease-in-out;
}

.content {
  max-width: 740px;
  margin: 1rem auto 1rem auto;
}

.particles-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  z-index: -1;

  & > .wrapper {
    width: 100%;
    height: 100vh;

    & > canvas {
      position: absolute;
    }
  }
}

.theme-changer {
  margin: 1rem 0 0 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.link-container {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

a {
  color: unset;
  text-decoration: none;
}

.main {
  position: relative;
  padding: 0 1rem;
}

.theme-picker-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  @media screen and (max-width: 319px) {
    display: none;
  }
}

.slow-fade-enter {
  opacity: 0.01;
}

.slow-fade-enter.slow-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.slow-fade-leave {
  opacity: 1;
}

.slow-fade-leave.slow-fade-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}
